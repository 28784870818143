const spacing = {
    'screen-mobile': 'calc(100dvh - 104px)',
    'screen-desktop': 'calc(100dvh - var(--dynamic-desktop-height))',
};
/** @type {import('tailwindcss').Config} */
export default {
    content: [
        './controller/**/*.{js,ts,vue}',
        './components/**/*.{js,ts,vue}',
        './pages/**/*.{js,ts,vue}',
        './layouts/**/*.vue',
        './plugins/**/*.{js,ts}',
        './nuxt.config.{js,ts}',
        './app.vue',
        './node_modules/primevue/**/*.{vue,ts}',
    ],
    safelist: [
        // Make sure color mapper works (composables/style.ts)
        'bg-[--custom-background-color]',
        {
            pattern: /(bg|from)-(woom-black|woom-grey|woom-green-moss|white)/,
        },
        {
            pattern: /(opacity)-(10|20|30|40|50|60|70|80|90|100)/,
        },
    ],
    theme: {
        // https://www.sketch.com/s/d5c5b81a-6387-4b69-a27e-19a117a47eb4/a/wLgd9LA
        fontFamily: {
            sans: [
                'WoomMain',
                'ui-sans-serif',
                'system-ui',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
                '"Noto Color Emoji"',
            ],
            mono: ['WoomMono', 'ui-monospace', 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'],
        },
        // https://www.sketch.com/s/d5c5b81a-6387-4b69-a27e-19a117a47eb4/a/uuid/30A54A47-C46A-4A1E-B39D-20E35B8BD027
        fontSize: {
            '2xs': '0.75rem', // https://www.sketch.com/s/68f4cf5d-f692-4dfe-ad8c-6766c2e8c59d/a/RypOY7Z#Inspect
            xs: ['0.8125rem', '1.1875rem'],
            sm: ['0.875rem', '1.1875rem'],
            base: ['1rem', '1.5rem'],
            lg: ['1.25rem', '1.75rem'],
            xl: ['1.5rem', '2rem'],
            '2xl': ['1.75rem', '2.25rem'],
            '3xl': ['2rem', '2.5rem'],
            '4xl': ['2.5rem', '3rem'],
            '5xl': ['3rem', '3.5rem'],
            '6xl': ['3.5rem', '4rem'],
        },
        // https://www.sketch.com/s/d5c5b81a-6387-4b69-a27e-19a117a47eb4/p/4F632024-3684-4163-BEE1-B9F788E7E5E5/canvas
        screens: {
            xs: '300px',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1264px',
            '2xl': '1440px',
        },
        extend: {
            // https://www.sketch.com/s/d5c5b81a-6387-4b69-a27e-19a117a47eb4/a/Kvq19vA
            fontWeight: {
                book: '350',
            },
            colors: {
                /**
                 * COLOR MIGRATION
                 * black        => woom-black
                 * white        => woom-white
                 * primary      => woom-red
                 * secondary    => woom-blue-electric
                 * tertiary     => woom-yellow
                 * accent       => woom-green
                 * grey-100     => woom-white-smoke
                 */
                woom: {
                    beige: {
                        DEFAULT: '#F6F3ED',
                    },
                    black: {
                        DEFAULT: '#222222',
                        charcoal: '#36454F',
                        'charcoal-metallic': '#32323C',
                    },
                    blue: {
                        DEFAULT: '#0095DB',
                        'bubble-gum': '#6CD1EF',
                        'cosmic-blurple': '#0071BD',
                        electric: '#006AD1',
                        ice: '#E6F5F0',
                        magnetic: '#2B3CB2',
                        metallic: '#006EAA',
                        midnight: '#003C71',
                    },
                    brown: {
                        sandy: '#F4A460',
                    },
                    coral: {
                        neon: '#FF6F69',
                    },
                    grey: {
                        DEFAULT: '#F5F5F5',
                        graphite: '#41474B',
                        mid: '#D8D8D8',
                        moon: '#707372',
                        pale: '#F0F0F5',
                        pebble: '#D0CDCA',
                    },
                    green: {
                        DEFAULT: '#279C38',
                        'atomic-neon': '#C1E100',
                        jungle: '#00BD71',
                        'lizard-lime': '#C8DA0B',
                        mint: '#00C389',
                        'mint-ready': '#BEFAD2',
                        moss: '#AAB991',
                    },
                    orange: {
                        flame: '#FF6D00',
                    },
                    pink: {
                        hot: '#FA196E',
                        marshmallow: '#FFAAD2',
                        power: '#F372A6',
                    },
                    purple: {
                        'cosmic-blurple': '#731C7F',
                        haze: '#9406FF',
                        lilac: '#CDB4EB',
                    },
                    red: {
                        // also red and woomRed and primary
                        DEFAULT: '#EB0613',
                        anniversary: '#D5092D',
                        formula: '#F00000',
                        hover: '#BC040F',
                    },
                    'terra-coppa': '#AA7A5B',
                    turquoise: {
                        metallic: '#199BA0',
                    },
                    violet: {
                        wild: '#BF9BDE',
                    },
                    white: {
                        DEFAULT: '#FFFFFF',
                        smoke: '#F5F5F5',
                    },
                    yellow: {
                        DEFAULT: '#FAB900',
                        'atomic-neon': '#FAE900',
                        banana: '#FFE900',
                        vibrant: '#FDE122',
                    },
                },
            },
            aspectRatio: {
                '2/3': '2 / 3',
                '3/2': '3 / 2',
                '3/4': '3 / 4',
                '4/3': '4 / 3',
                '1/1': '1 / 1',
            },
            spacing,
            minHeight: spacing,
            gridTemplateRows: {
                'screen-mobile': `minmax(0, ${spacing['screen-mobile']})`,
                'screen-desktop': `minmax(0, ${spacing['screen-desktop']})`,
            },
            strokeWidth: {
                3: '3px',
            },
            animation: {
                'spin-slow': 'spin 2s linear infinite',
                // Only for use on icon buttons
                pop: 'pop 0.3s ease-in-out',
            },
            zIndex: {
                xs: '20',
                sm: '30',
                md: '40',
                lg: '50',
                xl: '60',
                '2xl': '70',
                '3xl': '80',
            },
            transitionTimingFunction: {
                'in-out-smooth': 'cubic-bezier(0.42, 0, 0.58, 1)',
            },
            keyframes: {
                pop: {
                    '0%': { transform: 'scale(1)' },
                    '50%': { transform: 'scale(1.3)' },
                    '100%': { transform: 'scale(1)' },
                },
            },
            boxShadow: {
                'md-top': ['0 -4px 6px -4px rgb(0 0 0 / 0.1)', '0 -2px 4px -2px rgb(0 0 0 / 0.1)'],
            },
        },
    },
    plugins: [],
};
